import StoreItemsModule from "@/config/store/StoreItemsModule"

const BASE_URI = "/companies/car_types"

import extractMutations from "./mutations"
import extractActions from "./actions"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"]
})

store.mergeState({
  itemsGrouped: []
})

store.mergeMutations(extractMutations())
store.mergeActions(extractActions(BASE_URI))

export default store
