export const defaultCarClassObject = () => ({
  code: null,
  name: null,
  companies_car_type_id: null,
  companies_car_model_ids: [],
  image_title: null,
  image_url: null,
  transmission: "automatic",
  capacity: null,
  displacement: {
    from: null,
    to: null
  },
  non_smoking: false,
  carrying_capacity: {
    large_suitcase: null,
    small_suitcase: null,
    travel_bag: null
  },
  description: null
})
